import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => {
    return (
        <Layout>
            <SEO title={'Not found'}/>
            <h1 className={'text-center text-orange pt-5 mt-5'}>Page not found</h1>
            <p className={'text-center text-light'}>Sorry, we couldn’t find what you were looking for.</p>
        </Layout>
    )
}

export default NotFoundPage
